import axios from "axios";
import apiConfig from "../../config/api.config";
axios.defaults.baseURL = apiConfig.baseUrl;
import * as api from "./modules";
// service.interceptors.request.use(
//   config => {
//     // config.headers['X-Userinfo'] = ''
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );
//
// service.interceptors.response.use(
//   response => {
//     return response;
//   },
//   err => {
//
//     return err
//   }
// );

export default api;
