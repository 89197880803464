import Vue from "vue";
import VueRouter from "vue-router";
import { getToken, removeToken } from "@/utils/auth";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home/index.vue")
  },
  {
    path: "/transfer",
    name: "transfer",
    meta: { hiddenHeader: true },
    component: () => import("@/views/home/transfer.vue")
  },
  {
    path: "/login",
    name: "login",
    meta: { hiddenHeader: true },
    component: () => import("@/views/login/index.vue")
  },
  {
    path: "/UserAgreement",
    name: "UserAgreement",
    component: () => import("@/views/login/UserAgreement.vue")
  },
  {
    path: "/PrivacyPolicy",
    name: "PrivacyPolicy",
    component: () => import("@/views/login/PrivacyPolicy.vue")
  },
  {
    path: "/AboutUs",
    name: "AboutUs",
    component: () => import("@/views/login/AboutUs.vue")
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    component: () => import("@/views/login/ContactUs.vue")
  },
  {
    path: "/video",
    name: "video",
    component: () => import("@/views/video/index.vue"),
    children: [
      {
        path: "display",
        name: "videoDisplay",
        meta: { keepalive: true },
        component: () => import("@/views/video/display/index.vue")
      },
      {
        path: "preview",
        name: "videoPreview",
        meta: { keepalive: true },
        component: () => import("@/views/video/preview/index.vue")
      },
      {
        path: "search",
        name: "videoSearch",
        meta: { keepalive: true },
        component: () => import("@/views/video/search/index.vue")
      }
    ]
  },
  {
    path: "/picture",
    name: "picture",
    component: () => import("@/views/picture/index.vue"),
    children: [
      {
        path: "display",
        name: "pictureDisplay",
        meta: { keepalive: true },
        component: () => import("@/views/picture/display/index.vue")
      },
      {
        path: "preview",
        name: "picturePreview",
        meta: { keepalive: true },
        component: () => import("@/views/picture/preview/index.vue")
      },
      {
        path: "search",
        name: "pictureSearch",
        meta: { keepalive: true },
        component: () => import("@/views/picture/search/index.vue")
      }
    ]
  },
  {
    path: "/activity",
    name: "activity",
    component: () => import("@/views/activity/index.vue"),
    children: [
      {
        path: "display",
        name: "activityDisplay",
        meta: { keepalive: true },
        component: () => import("@/views/activity/display/index.vue")
      },
      {
        path: "detail",
        name: "activityDetail",
        meta: { keepalive: true },
        component: () => import("@/views/activity/detail/index.vue")
      }
    ]
  },
  {
    path: "/personalCenter",
    name: "personalCenter",
    meta: { keepalive: true },
    component: () => import("@/views/personalCenter/index.vue")
  },
  {
    path: "/morePictureList",
    name: "morePictureList",
    meta: { keepalive: true },
    component: () => import("@/views/personalCenter/morePictureList.vue")
  },
  {
    path: "/moreVideoList",
    name: "moreVideoList",
    meta: { keepalive: true },
    component: () => import("@/views/personalCenter/moreVideoList.vue")
  },
  {
    path: "/personalInfo",
    name: "personalInfo",
    component: () => import("@/views/personalInfo/index.vue")
  },
  {
    path: "/news",
    name: "news",
    meta: { keepalive: true },
    component: () => import("@/views/news/index.vue")
  },
  {
    path: "/authorWall",
    name: "authorWall",
    meta: { keepalive: true },
    component: () => import("@/views/authorWall/index.vue")
  },
  {
    path: "/uploadProductionImg",
    name: "uploadProductionImg",
    component: () => import("@/views/uploadProduction/index.vue")
  },
  {
    path: "/uploadProductionVideo",
    name: "uploadProductionVideo",
    component: () => import("@/views/uploadProduction/video.vue")
  }
];

const router = new VueRouter({
  routes,
  //配置滚动行为，跳转到新的路由界面滚动条的位置
  // scrollBehavior() {
  //   return { x: 0, y: 0 };
  // }
  //scrollBehavior 函数接收 to和 from 路由对象，如 Navigation Guards。
  //第三个参数 savedPosition 当且仅当 popstate 导航 (通过浏览器的 前进/后退 按钮触发) 时才可用。
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      console.log("savedPositionsavedPositionsavedPosition", savedPosition);
      //此处意思 即若回退页面，返回savedPosition，且页面不置顶
      return savedPosition;
    } else {
      //此处意思 若页面跳转新页面，则页面置顶
      console.log("此处意思 若页面跳转新页面，则页面置顶");
      return { x: 0, y: 0, behavior: "smooth" };
    }
  }
});
router.beforeEach(async (to, from, next) => {
  const blackList = [
    "/personalInfo",
    "/uploadProductionImg",
    "/uploadProductionVideo",
    "/news",
    "/morePictureList",
    "/moreVideoList"
  ];
  const hasToken = getToken();
  if (hasToken) {
    if (to.path === "/login") {
      next();
      removeToken();
    } else {
      const hasGetUserName = store.getters.username;
      if (hasGetUserName) {
        next();
      } else {
        try {
          // get user info
          await store.dispatch("user/getInfo");
          next();
        } catch (error) {
          console.log(error, "error");
          removeToken();
          next("/login");
        }
      }
    }
  } else {
    if (blackList.includes(to.path)) {
      next("/login");
    } else {
      next();
    }
  }
});

export default router;
