import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "@/api/index.js";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueCanvasPoster from "vue-canvas-poster";
Vue.use(ElementUI);
// canvas poster
Vue.use(VueCanvasPoster);
import "@/assets/css/global.css";
import "@/styles/app.scss";
Vue.config.productionTip = false;
Vue.prototype.$api = api;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
