<template>
  <div class="app-wrapper">
    <header>
      <div class="header-con" ref="header">
        <div class="nav">
          <div class="logo" @click="goIndex">
            <img src="@/assets/images/logo.png" class="logo-img" />
          </div>
          <ul class="navigation-bar">
            <li
              v-for="item in routers"
              :key="item.name"
              @click="navigator(item)"
              :class="{ active: item.paths.includes(currentRoutePath) }"
            >
              {{ item.label }}
              <div class="line"></div>
            </li>
            <!-- <div>
              <el-dropdown>
                <span class="el-dropdown-link">
                  <i
                    class="el-icon-more"
                    style="transform:rotate(90deg);color:#fff;height:30px;"
                  ></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>版权保护</el-dropdown-item>
                  <el-dropdown-item divided>关于我们</el-dropdown-item>
                  <el-dropdown-item divided>商务合作</el-dropdown-item>
                  <el-dropdown-item divided>常见问题</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div> -->
            <div class="search-box" v-if="isShow">
              <smallSearch @change="searchPicture"></smallSearch>
            </div>
          </ul>
        </div>
        <div class="user-info-box">
          <div class="user-info" v-if="isLogin">
            <div class="news-box" v-mouseoutside="handleClose">
              <img
                class="news-icon"
                ref="newsIcon"
                @mouseenter="handleNewsShow"
                src="@/assets/images/news.png"
                alt=""
              />
              <span class="news-num" v-if="total">{{ total }}</span>
              <div class="news-items" ref="newsItems" v-show="showNewsBox">
                <div class="news-items-box">
                  <div class="news-item" @click="handleNews(5)">
                    <div class="left">
                      <span class="text">点赞</span>
                      <span class="num" v-if="notRedList.dianzan">{{
                        notRedList.dianzan
                      }}</span>
                    </div>
                    <div class="right">
                      <img
                        style="width:19px;height:17px"
                        class="img"
                        src="@/assets/images/news-like.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="news-item" @click="handleNews(2)">
                    <div class="left">
                      <span class="text">收藏</span>
                      <span class="num" v-if="notRedList.shoucang">{{
                        notRedList.shoucang
                      }}</span>
                    </div>
                    <div class="right">
                      <img
                        style="width:16px;height:17px"
                        class="img"
                        src="@/assets/images/news-shoucang.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="news-item" @click="handleNews(1)">
                    <div class="left">
                      <span class="text">粉丝</span>
                      <span class="num" v-if="notRedList.guanzhu">{{
                        notRedList.guanzhu
                      }}</span>
                    </div>
                    <div class="right">
                      <img
                        class="img"
                        style="width:19px;height:18px"
                        src="@/assets/images/news-fensi.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="news-item" @click="handleNews(4)">
                    <div class="left" style="border-bottom:none">
                      <span class="text">评论</span>
                      <span class="num" v-if="notRedList.pinglun">{{
                        notRedList.pinglun
                      }}</span>
                    </div>
                    <div class="right">
                      <img
                        class="img"
                        style="width:18px;height:15px"
                        src="@/assets/images/news-pinglun.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="user-box">
              <img
                @click="jumpUCenter"
                class="head-image"
                :src="headimage || defaultHeadimage"
              />
              <span class="nick-name"
                ><el-dropdown @command="handleCommandNickname">
                  <div class="nickname">{{ nickname }}</div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1">账号详情</el-dropdown-item>
                    <el-dropdown-item command="2">退出</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown></span
              >
            </div>
            <div class="operate-box">
              <el-dropdown @command="handleCommand">
                <div class="btn-click">
                  <img
                    class="upload-arrow"
                    src="@/assets/images/upload-arrow.png"
                  />
                  <span class="btn-click-text">上传</span>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="1">图片</el-dropdown-item>
                  <el-dropdown-item command="2">视频</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- <div class="report">
              <img
                class="report-icon"
                src="@/assets/images/report-icon.png"
                alt=""
              />
            </div> -->
          </div>
          <div class="user-info-login" v-else @click="navigatorLogin">
            <img src="@/assets/images/person.png" />
            <span>登录/注册</span>
          </div>
        </div>
      </div>
    </header>
    <main class="main-container">
      <slot></slot>
      <footer v-if="currentRoutePath !== '/'">
        <div class="top">
          <span @click="handleAboutUs">关于我们</span>
          <span @click="handleAgreement">用户协议</span>
          <span @click="handlePrivacy">隐私政策</span>
          <span @click="handleContactUs">联系我们</span>
        </div>
        <div class="down">
          <div class="copyright">
            Copyright © 2022 gjdl960.com 中国国家地理·频道 版权所有
          </div>
          <div class="copy-icp">
            <img class="img-icon" src="@/assets/images/beian.png" alt="" />
            <span class="text">
              京ICP证090072号 京ICP备10014304号-4 京ICP备10014304号-5
              京公网安备11010502048871</span
            >
          </div>
        </div>
      </footer>
    </main>
  </div>
</template>

<script>
const mouseoutside = {
  // 初始化指令
  bind(el, binding) {
    function documentHandler(e) {
      if (el.contains(e.target)) {
        return false;
      }
      if (binding.expression) {
        binding.value(e);
      }
    }
    el.__vueMousOutsideside__ = documentHandler;
    document.addEventListener("mouseover", documentHandler);
  },
  update() {},
  unbind(el) {
    document.removeEventListener("mouseover", el.__vueMousOutsideside__);
    delete el.__vueMousOutsideside__;
  }
};
import smallSearch from "../../components/smallSearch/index";
export default {
  name: "index",
  components: { smallSearch },
  directives: { mouseoutside },
  data() {
    return {
      windowsWidth: "", //窗口宽度
      notRedList: {}, //未读消息
      showNewsBox: false,
      fixedLeft: "", //消息框距离左部位置
      defaultHeadimage: require("../../assets/images/headPortrait.png"),
      routers: [
        {
          label: "首页",
          name: "home",
          path: "/",
          paths: ["/"]
        },
        {
          label: "图片",
          name: "picture",
          path: "/picture/display",
          paths: ["/picture/display", "/picture/preview", "/picture/search"]
        },
        {
          label: "视频",
          name: "video",
          path: "/video/display",
          paths: ["/video/display", "/video/preview", "/video/search"]
        },
        {
          label: "活动",
          name: "activity",
          path: "/activity/display",
          paths: ["/activity/display", "/activity/detail"]
        },
        {
          label: "作者墙",
          name: "authorWall",
          path: "/authorWall",
          paths: ["/authorWall"]
        }
      ]
    };
  },
  async created() {
    //获取消息总数
    await this.$store.dispatch("news/getTotal");
  },
  mounted() {
    let windowsWidth = document.documentElement.clientWidth;
    let that = this;
    that.windowsWidth = windowsWidth;
    window.onresize = function() {
      const curWindowsWidth = document.documentElement.clientWidth;
      windowsWidth = curWindowsWidth;
      that.windowsWidth = windowsWidth;
    };
  },
  watch: {
    windowsWidth: {
      handler: function(oldValue) {
        if (oldValue) {
          this.fixedNews(oldValue);
        }
      },
      immediate: true
    }
  },
  methods: {
    fixedNews(windowsWidth) {
      console.log("fixedNewsfixedNewsfixedNews", windowsWidth);
      if (!this.$refs.newsItems) return;
      let that = this;
      if (windowsWidth > 1365) {
        that.$refs.newsItems.style.position = "absolute";
        that.$refs.newsItems.style.top = "15px";
        that.$refs.newsItems.style.left = "-40px";
      } else {
        that.$nextTick(() => {
          console.log("windowsWidth <= 1365");
          const fixedLeft = that.$refs.newsIcon.getBoundingClientRect().left;
          that.fixedLeft = fixedLeft;
          console.log(123, that.$refs.newsItems);
          that.$refs.newsItems.style.position = "fixed";
          that.$refs.newsItems.style.top = "35px";
          that.$refs.newsItems.style.left = fixedLeft - 45 + "px";
          // this.$refs.header.style.overflow = "visible";
        });
        that.$refs.header.onscroll = e => {
          console.log(e);
          that.$nextTick(() => {
            console.log("windowsWidth <= 1365");
            const fixedLeft = that.$refs.newsIcon.getBoundingClientRect().left;
            that.fixedLeft = fixedLeft;
            that.$refs.newsItems.style.position = "fixed";
            that.$refs.newsItems.style.top = "35px";
            that.$refs.newsItems.style.left = fixedLeft - 45 + "px";
          });
        };
      }
    },
    searchPicture(val) {
      console.log(val);
    },
    goIndex() {
      this.$router.push("/");
    },
    navigator(val) {
      const { path } = val;
      this.$router.push(path);
    },
    navigatorLogin() {
      this.$router.push({ name: "login", params: { type: "login" } });
    },
    handleCommand(command) {
      if (command == 1) {
        this.$router.push({
          name: "uploadProductionImg"
        });
      } else {
        this.$router.push({
          name: "uploadProductionVideo"
        });
      }
    },
    handleCommandNickname(command) {
      if (command == 1) {
        this.$router.push({
          name: "personalInfo"
        });
      } else {
        this.$router.push({
          name: "login"
        });
        localStorage.removeItem("user_id");
        localStorage.removeItem("user_info");
        this.$store.state.user.username = "";
        this.$store.state.user.headimage = "";
      }
    },
    handleClose() {
      this.showNewsBox = false;
    },
    async handleNewsShow() {
      this.showNewsBox = true;
      let curWindowsWidth = document.documentElement.clientWidth;
      this.fixedNews(curWindowsWidth);
      //获取消息列表未读数
      let user_id = localStorage.getItem("user_id");
      if (user_id) {
        this.$store.dispatch("news/getTotal");
        const { data } = await this.$api.getNotresList({ user_id });
        if (data.code === 1) {
          console.log(999, data);
          this.notRedList = data.data;
        }
      }
    },
    handleNews(type) {
      console.log(type);
      this.showNewsBox = false;
      let flag = false;
      if (this.$route.name === "news") flag = true;
      this.$router.push({ name: "news", query: { type } });
      if (flag) location.reload();
    },
    jumpUCenter() {
      const user_id = localStorage.getItem("user_id");
      const { PageUserId } = this.$route.query;
      if (PageUserId == user_id) {
        return;
      }
      this.$router.push({
        name: "personalCenter",
        query: { PageUserId: user_id }
      });
      // if (PageUserId) {
      //   location.reload();
      // }
    },
    handleAboutUs() {
      this.$router.push({ name: "AboutUs" });
    },
    handleAgreement() {
      this.$router.push({ name: "UserAgreement" });
    },
    handlePrivacy() {
      this.$router.push({ name: "PrivacyPolicy" });
    },
    handleContactUs() {
      this.$router.push({ name: "ContactUs" });
    }
  },
  computed: {
    currentRoutePath() {
      return this.$route.path;
    },
    isLogin() {
      return localStorage.getItem("user_id");
    },
    isShow() {
      return !(
        this.currentRoutePath.indexOf("picture") !== -1 ||
        this.currentRoutePath.indexOf("video") !== -1 ||
        this.currentRoutePath == "/"
      );
    },
    nickname() {
      return this.$store.getters.username;
    },
    headimage() {
      return this.$store.getters.avatar;
    },
    total() {
      return this.$store.getters.total;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.app-wrapper {
  height: 100%;
}
.main-container {
  height: calc(100% - 60px);
  footer {
    background: #000000;
    color: #ffffff;
    height: 198px;
    .top {
      display: flex;
      justify-content: space-between;
      width: 580px;
      margin: 0 auto;
      span {
        display: inline-block;
        padding: 57px 0 47px;
        font-size: 15px;
        font-family: PingFangSC-Light;
        font-weight: 300;
        color: #9fa0a0;
        cursor: pointer;
      }
    }
    .down {
      width: 580px;
      margin: 0 auto;
      text-align: center;
      .copyright {
        font-size: 12px;
        font-family: PingFangSC-Light;
        font-weight: 300;
        color: #9fa0a0;
        padding-bottom: 13px;
      }
      .copy-icp {
        .img-icon {
          width: 11px;
          height: 12px;
          margin-right: 10px;
        }
        .text {
          font-size: 12px;
          font-family: PingFangSC-Light;
          font-weight: 300;
          color: #9fa0a0;
        }
      }
    }
  }
}
header {
  height: 60px;
  background: rgba(0, 0, 0, 1);
  width: 100%;
  .header-con {
    height: 60px;
    position: fixed;
    background: rgba(25, 25, 25, 1);
    padding: 0 60px 0 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    .nav {
      display: flex;
      align-items: center;
      .logo-img {
        width: 184px;
      }
      .navigation-bar {
        margin-left: 30px;
        display: flex;
        li {
          color: #ffffff;
          margin: 0 24px;
          cursor: pointer;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          white-space: nowrap;
          height: 37px;
          line-height: 37px;
          .line {
            width: 85%;
            height: 6px;
            // margin-top: 3px;
            background: #e60021;
            border-radius: 10px;
            position: relative;
            display: none;
            &:after {
              content: " ";
              position: absolute;
              right: -10px;
              top: 0;
              width: 6px;
              height: 6px;
              background: #e60021;
              border-radius: 6px;
            }
          }
        }
        .search-box {
          margin: 0 20px;
        }
        .active {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          .line {
            display: block;
          }
        }
      }
    }
    .user-info-box {
      .user-info {
        display: flex;
        align-items: center;
        .news-box {
          position: relative;
          margin: 0 20px 0 25px;
          cursor: pointer;
          .news-icon {
            display: block;
            height: 19px;
            width: 19px;
          }
          .news-num {
            font-size: 12px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            border-radius: 50%;
            color: #ffffff;
            background: #e60021;
            position: absolute;
            right: -16px;
            top: -16px;
          }
          .news-items {
            padding-top: 19px;
            position: absolute;
            top: 15px;
            left: -40px;
            width: 109px;
            height: 179px;
            .news-items-box {
              background: #ffffff;
              border-radius: 7px;
            }
            .news-item {
              font-size: 15px;
              font-family: PingFangSC-Regular;
              font-weight: 400;
              margin: 0 10px;
              color: #595757;
              display: flex;
              height: 45px;
              align-items: center;
              .left {
                flex: 1;
                height: 45px;
                line-height: 45px;
                text-align: center;
                border-bottom: 1px solid #a0a0a0;
                position: relative;
                .num {
                  position: absolute;
                  top: 8px;
                  right: 0px;
                  width: 15px;
                  height: 15px;
                  line-height: 15px;
                  font-size: 10px;
                  font-family: PingFangSC-Regular;
                  font-weight: 400;
                  color: #ffffff;
                  text-align: center;
                  border-radius: 15px;
                  background: #e60021;
                }
              }
              .right {
                margin-left: 5px;
                img {
                  width: 19px;
                }
              }
            }
          }
        }
        .user-box {
          display: flex;
          align-items: center;
          cursor: pointer;
          .head-image {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
          .nick-name {
            padding: 0 10px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffffff;
            .nickname {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #ffffff;
              white-space: nowrap;
            }
          }
        }
        .operate-box {
          .btn-click {
            width: 105px;
            height: 35px;
            border-radius: 19px;
            background: #e60021;
            text-align: center;
            font-size: 20px;
            font-family: PingFangSC-Medium;
            font-weight: 500;
            color: #ffffff;
            line-height: 35px;
            color: #ffffff;
            cursor: pointer;
            .btn-click-text {
              font-size: 20px;
              font-family: PingFangSC-Medium;
              font-weight: 500;
              color: #ffffff;
              letter-spacing: 8px;
            }
          }
          .upload-arrow {
            position: relative;
            top: 4px;
            margin-right: 5px;
            height: 24px;
            width: 22px;
          }
        }
        .report {
          .report-icon {
            margin-left: 19px;
            width: 25px;
            height: 21px;
          }
        }
      }
      .user-info-login {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #ffffff;
        width: 300px;
        height: 30px;
        cursor: pointer;
        img {
          width: 20px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.el-dropdown-menu {
  min-width: 80px;
  padding: 0;
  .el-dropdown-menu__item {
    font-size: 15px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #595757;
    height: 50px;
    line-height: 50px;
    padding: 0;
    margin: 0 8px;
    text-align: center;
    border-bottom: 1px solid #a0a0a0;
    &:hover {
      background: transparent;
      color: #595757;
    }
    &:nth-child(2) {
      border-bottom: none;
    }
  }
}
@media screen and (max-width: 1365px) {
  // .search-box {
  // display: none;
  // }
  .header-con {
    overflow-x: auto;
  }
}
</style>
