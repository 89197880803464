import axios from "axios";

// export * as common from "./common";
export const getResourceList = data =>
  axios.post("/resource/getResourceList", data);

export const getCountryList = () => axios.post("/adress/getcountrylist");

export const getProvinceList = data =>
  axios.post("/adress/getProvinceList", data);

export const getCityList = data => axios.post("/adress/getCityList", data);

export const getAreaList = data => axios.post("/adress/getAreaList", data);
//注册接口
export const getRegister = data => axios.post("/Frontuser/register_user", data);
//登录接口
export const getLogin = data => axios.post("/Frontuser/login", data);
//消息总数接口
export const getNotrespushcount = data =>
  axios.post("/Frontusercenter/notreadpushcount", data);
//消息未读列表接口
export const getNotresList = data =>
  axios.post("/Frontpushmsg/pushmsg_count", data);
//消息未读变为已读接口
export const getEditpushmsg = data =>
  axios.post("/Frontusercenter/editpushmsg", data);
//获取验证码接口
export const getCode = data => axios.post("/Frontuser/sent_code", data);
//修改密码接口
export const getChangePwd = data =>
  axios.post("/Frontuser/edit_password", data);
//作者墙
export const getAuthorWallList = () =>
  axios.post("/Frontauthorwall/authorwall_list");
//首页
export const getindexList = () => axios.post("/frontindex/index_list");
//图片&视频接口
export const getblockList = data => axios.post("/frontblock/block_list", data);
//作品详情
export const getresourceInfo = data =>
  axios.post("/frontresource/resourceinfo", data);
//活动列表
export const getActivityList = () => axios.post("/frontactivity/activity_list");
//活动详情
export const getActivityDetail = data =>
  axios.post("/frontactivity/get_activity_info", data);
// 作品点赞接口
export const postClickZan = data => axios.post("/frontresource/clickzan", data);
// 作品收藏接口
export const postShouCang = data => axios.post("/frontresource/shoucang", data);
// 作品转发记录接口
export const postZhuanFa = data => axios.post("/frontresource/zhuanfa", data);
// 关注作者接口
export const postFollow = data => axios.post("/Frontresource/guanzhu", data);
// 添加评论接口
export const postAddComment = data => axios.post("/comment/addComment", data);
// 获取评论列表接口
export const getCommentList = data =>
  axios.post("/comment/getCommentList", data);
// 获取消息列表接口
export const getNewsList = data =>
  axios.post("/Frontpushmsg/pushmsg_list", data);
//上传图片到阿里云
export const postUpImages = data =>
  axios.post("/Frontresource/uploadimage", data);
//上传视频到阿里云
export const postUpVideo = data =>
  axios.post("/Frontresource/uploadvideo", data);
//上传作品
export const postUpProduction = data =>
  axios.post("/Frontresource/uploadresource", data);
//上传页面获取分类
export const getTaglist = () => axios.post("/Frontresource/gettaglist");
// 获取个人中心信息接口
export const getPersonalList = data =>
  axios.post("/Frontusercenter/getuserresource", data);
// 获取个人中心喜欢&收藏查看更多图片接口
export const getLookMorePictureList = data =>
  axios.post("/Frontusercenter/getmorereource", data);
// 获取个人中心喜欢&收藏查看更多视频接口
export const getLookMoreVideoList = data =>
  axios.post("/Frontusercenter/getmorevideo", data);
//获取用户信息
export const getUserinfo = data =>
  axios.post("/Frontusercenter/getuserinfo", data);
//获取用户协议和隐私政策
export const getCopywriting = data =>
  axios.post("/frontuser/get_copywriting", data);
//修改用户信息
export const editUserinfo = data =>
  axios.post("/Frontusercenter/edituserinfo", data);
//搜索接口
export const getSearch = data => axios.post("/Frontsearch/commensearch", data);
//删除评论接口
export const postDeleteComment = data =>
  axios.post("/commentmanage/delcomment", data);
// 删除图片接口
export const deletePicture = data => axios.post("/resource/delResource", data);