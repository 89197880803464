<template>
  <div id="app">
    <div class="app" v-if="!$route.meta.hiddenHeader">
      <layout>
        <keep-alive v-if="$route.meta.keepalive">
          <router-view />
        </keep-alive>
        <router-view v-else />
      </layout>
    </div>
    <div class="app" v-if="$route.meta.hiddenHeader">
      <router-view />
    </div>
    <!-- <layout v-if="!$route.meta.hiddenHeader">
      <router-view :key="key" />
    </layout>
    <router-view v-else :key="key" /> -->
  </div>
</template>
<script>
import layout from "@/components/layout/index.vue";
export default {
  name: "App",
  components: {
    layout
  },
  computed: {
    key() {
      return this.$route.name
        ? this.$route.name + +new Date()
        : this.$route + +new Date();
    }
  }
};
</script>
<style lang="scss">
#app {
  font-family: SourceHanSansCN-Light, "Helvetica Neue", Helvetica, "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", 微软雅黑, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  position: relative;
  box-sizing: border-box;
  font-size: 14px;
}
.app {
  height: 100%;
}
</style>
