import api from "@/api/index.js";
import { getToken } from "@/utils/auth";

const state = {
  total: 0
};

const mutations = {
  SET_TOTAL: (state, total) => {
    state.total = total;
  }
};
const actions = {
  // 获取消息总数
  async getTotal({ commit }) {
    const token = getToken();
    if (token) {
      const { data } = await api.getNotrespushcount({ user_id: token });
      if (data.code === 1) {
        commit("SET_TOTAL", data.data.total);
      }
    } else {
      commit("SET_TOTAL", 0);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
