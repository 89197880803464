import api from "@/api/index.js";
import { getToken } from "@/utils/auth";

const getDefaultState = () => {
  return {
    username: "",
    avatar: ""
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },
  SET_USERNAME: (state, username) => {
    state.username = username;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  }
};
const actions = {
  // get user info
  async getInfo({ commit }) {
    const token = getToken();
    const { data } = await api.getUserinfo({ user_id: token });
    if (data.data) {
      const { nickname, headimage } = data.data;
      commit("SET_USERNAME", nickname);
      commit("SET_AVATAR", headimage);
      localStorage.setItem("user_info", JSON.stringify(data.data));
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
