<template>
  <div class="small-search">
    <el-select
      v-model="selectType"
      placeholder="请选择"
      class="select-style"
      v-if="isSelect"
    >
      <el-option
        v-for="item in selectTypes"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
    <el-input
      v-model="searchText"
      placeholder="搜索作品"
      class="input-item"
      @keyup.enter.native="search"
    />
    <div class="search-icon">
      <img src="@/assets/images/search.png" @click="search" />
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      searchText: "",
      selectType: "picture",
      selectTypes: [
        {
          value: "picture",
          label: "图片"
        },
        {
          value: "video",
          label: "视频"
        }
      ]
    };
  },
  props: {
    isSelect: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    search() {
      if (this.selectType == "picture") {
        this.$router.push({
          name: "pictureSearch",
          query: { type: this.selectType, text: this.searchText }
        });
      } else {
        this.$router.push({
          name: "videoSearch",
          query: { type: this.selectType, text: this.searchText }
        });
      }
      this.$emit("change", { type: this.selectType, text: this.searchText });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.small-search {
  width: 270px;
  height: 39px;
  border: 1px solid rgba(155, 155, 155, 0.53);
  border-radius: 100px;
  padding: 2px 0;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  .select-style {
    width: 76px;
    border-right: 1px solid #c0c0c0;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-input__inner {
      background: transparent;
      border: none;
      color: #ebebeb;
      font-size: 15px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
    }
    .el-input__suffix {
      .el-select__caret {
        color: #dd7d8b;
      }
      .el-icon-arrow-up {
        &::before {
          content: "\e78f";
        }
      }
    }
  }
  .search-icon {
    width: 40px;
    display: flex;
    justify-content: center;
    img {
      width: 21px;
      height: intrinsic;
      cursor: pointer;
    }
  }
  .input-item {
    flex: auto;
    width: 155px;
    .el-input__inner {
      background-color: transparent;
      padding: 0 10px;
      outline: none;
      border: none;
      font-size: 15px;
      font-family: PingFangSC-Light;
      font-weight: 300;
      color: #9d9d9d !important;
      border-right: 1px solid #c0c0c0;
      height: 22px;
      line-height: 22px;
      border-radius: 0;
      &::placeholder {
        color: #9d9d9d;
      }
      &::-webkit-input-placeholder {
        /* WebKit browsers 适配谷歌 */
        color: #9d9d9d;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 适配火狐 */
        color: #9d9d9d;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ 适配火狐 */
        color: #9d9d9d;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10+  适配ie*/
        color: #9d9d9d;
      }
    }
  }
}
.el-select-dropdown {
  .el-select-dropdown__wrap {
    .el-select-dropdown__item {
      span {
        font-size: 15px !important;
        font-family: PingFangSC-Regular;
        font-weight: 400;
      }
    }
    .el-select-dropdown__item.selected {
      color: #dd7d8b;
    }
  }
}
</style>
